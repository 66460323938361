import "../styles/site.less";
import "vue-select/dist/vue-select.css";
import "vue-toast-notification/dist/theme-default.css";

export module MyFoldersPage {
    export function init(): void {
        import("./myFolders").then(function (page) {
            page.MyFolders.init();
        });
    }
}

export module EligibilityCenterPage {
    export function init(): void {
        import("./eligibilityCenter").then(function (page) {
            page.EligibilityCenter.init();
        });
    }
}