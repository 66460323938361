<template>
    <div>
        {{playerIndex + 1}}.
        <i v-for="(country, countryIndex) in player.countries" :key="countryIndex" :class="'flag flag-' + country.iso2" :title="country.name"></i>
        <a :href="'/player/'+player.id" class="name">{{player.name}} {{player.surname}}</a>
        <i v-if=player.has_video class="icon-youtube-play" title="Video available" style="color: #000 !important;"></i>
        <small>{{player.positions.map(x => x.abbr).join("&")}} &ndash; {{player.birthyear}} &ndash; {{metersToFeetsAndInches(player.height)}} &ndash; {{player.height}}m </small>
    </div>
</template>

<script>
export default {
   props: ["player", "playerIndex"],
   methods: {
        metersToFeetsAndInches(meters)  {
            const inCm = meters * 100;
            const realFeet = ((inCm*0.393700) / 12);
            var feet = Math.floor(realFeet);
            var inches = Math.round((realFeet - feet) * 12);
            if (inches === 12) {
              feet++;
              inches = 0;
            }

            return `${feet}'${inches}"`;
        },
   }
};
</script>