export module Api {
    export enum RequestType {
        post = "post",
        put = "put",
        delete = "delete"
    }

    export function get<TResponse>(url: string, token: string = "", cultureInfo = ""): Promise<TResponse> {
        // TODO: the use of token for API calls would be ideal
        //const authorization = (token) ? ["Authorization", `Bearer ${token}`] : [];
        //const acceptLanguage = (cultureInfo) ? ["Accept-Language", cultureInfo] : [];
    
        return fetch(url,
            {
                method: "GET",
                //headers: [acceptLanguage, authorization]
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json() as Promise<TResponse>;
            });
    }
    export function request<TRequest, TResponse>(requestType: RequestType, url: string, payload: TRequest = null, headers: Map<string, string> = new Map<string, string>()): Promise<TResponse> {
        headers.set("Content-Type", "application/json");

        return fetch(url, { method: requestType, headers: Array.from(headers.entries()), body: payload ? JSON.stringify(payload) : "" })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                if (response.status === 204) {
                    return {} as Promise<TResponse>;
                }
                return response.json() as Promise<TResponse>;
            });
    }

/*     export function post<TRequest, TResponse>(url: string, payload: TRequest, headers: Map<string, string> = new Map<string, string>()): Promise<TResponse> {
        headers.set("Content-Type", "application/json");

        return fetch(url, { method: "POST", headers: Array.from(headers.entries()), body: payload ? JSON.stringify(payload) : "" })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                if (response.status === 204) {
                    return {} as Promise<TResponse>;
                }
                return response.json() as Promise<TResponse>;
            });
    }

    export function put<TRequest, TResponse>(url: string, payload: TRequest, headers: Map<string, string> = new Map<string, string>()): Promise<TResponse> {
        headers.set("Content-Type", "application/json");

        return fetch(url, { method: "PUT", headers: Array.from(headers.entries()), body: payload ? JSON.stringify(payload) : "" })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                if (response.status === 204) {
                    return {} as Promise<TResponse>;
                }
                return response.json() as Promise<TResponse>;
            });
    } */
}
